
.header {

    color: white;
    display: flex;
    justify-content: space-between;
    padding: 1rem 3rem;
    font-size: 1.2rem;

    .brand-logo {
        text-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
        height: 28px;
        width: 28px;
        margin-left: 10px;
        background-image: url("./logo.svg");
        vertical-align: middle;
        background-size: contain;
        display: inline-block;
    }

    .brand-name {

        padding: 0.3rem 0.6rem 0 0.6rem;
    }

    .btn-sign-in {
        cursor: pointer;
        transition: all 0.2s ease-in-out;
        color: black;
        background-color: rgba(255, 255, 255, 0.95);
        border-radius: 2rem;

        .icon-sign-in {
            vertical-align: -3px;
        }
        font-size: 1rem;

        padding: 0.6rem 1.2rem;
    }

    .btn-sign-in:hover {
        color: #2f856f;
    }
}

.banner-figure {
    width: 100%;
    height: 80vh;
    background-image: url("https://static.spellbook.cloud/home/v1/clouds-v5.jpeg");
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    border-bottom: 1px solid #f1f1f1;
}

.banner-container {
    text-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    color: white;
    background-image: url("./dot-pattern.svg");
    background-size: 30px;
    margin-top: -100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    text-align: center;

    .h1 {
        margin-bottom: 1rem;
        font-size: 3.5rem;

        .text-magic {
            color: #55E6C1;
            font-family: 'Dancing Script', cursive;
            font-size: 5.5rem;
        }
    }

    .h1-lead {
        color: white;
        font-size: 1.5rem;
    }

    .banner-content {
        padding: 2rem;
        align-self: center;
    }
}

footer {
    text-align: center;
    color: white;
    padding: 1rem;
    background-color: #2C3A47;
}